 /*!
 *
 * tategaki.js
 *
 */

var App = App || {};

(function() {

    var tategaki = (function()
    {

        var initialize = function()
        {
            (new Taketori()).set({lang:'ja-jp', togglable:false, maxHeight:'240px'}).element('ul.tategaki').toVertical();
        };

        initialize();

        return {
        };
    })();


    /*--------------------------------------------------
      regist App
    ----------------------------------------------------*/
    App.tategaki = tategaki;

})();