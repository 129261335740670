/*!
 *
 * mainvisual.js
 *
 */


var App = App || {};

(function(){

    // メインビジュアル
    var slideshow = (function()
    {
        var
            $window,
            $container,
            $items,
            itemCount,
            interval,
            duration,
            current,
            old_current,
            timer,
            images_loaded
            ;

        var initialize = function()
        {

            $window        = $(window);
            $container     = $('.m-slideshow');
            $items         = $container.find('.item').css('opacity', 0).css('zIndex', 0);
            interval       = 6400;
            current        = 0;
            old_current    = 0;
            timer          = null;

            $items        = $container.find('.item').css('opacity', 0).css('zIndex', 0);
            itemCount     = $items.length;
            if (itemCount <= 1)
            {
                $items = $container.find('.item').css('opacity', 1);
                return;
            }
            $container.waitForImages(function()
            {
                play();
                resetTimer();
            });
        };

        var resetTimer = function()
        {
            stopTimer();
            timer = setInterval(function() {
                if ( current === ( itemCount - 1 ) ) {
                    current = 0;
                } else {
                    current++;
                }
                play();
            }, interval);
        };

        var stopTimer = function()
        {
            if ( timer ) {
                clearInterval( timer );
            }
        };

        var play = function()
        {
            $items.eq(old_current).css('zIndex', 0);
            $items.eq(current).css('zIndex', 1);
            TweenMax.to($items.eq(old_current), 1.7,
            {
                alpha: 0,
                ease : Power1.ease,
                delay : 0,
                onComplete:function()
                {

                }
            });
            TweenMax.to($items.eq(current), 1.8,
            {
                alpha: 1,
                ease : Power1.ease,
                delay : 0,
                onComplete:function()
                {
                    $items.not(':eq('+current+')').css({'opacity':0,'zIndex':0});
                    old_current = current;
                }
            });

        };

        return {
            initialize: initialize
        };
    })();


    /*--------------------------------------------------
      regist App
    ----------------------------------------------------*/
    App.slideshow = slideshow;

    $(function(){
        $('.m-slideshow').each(function()
        {
            App.slideshow.initialize();
        });
    });

}());

