 /*!
 *
 * gnav.js
 *
 */

var App = App || {};

(function() {

    // メニューの表示
    var gnav = (function()
    {
        var
            $window,
            $container,
            $navOpenBtn,
            $navs,
            $home;

        var initialize = function()
        {
            $window = $(window);
            $container = $('.globalnav');
            $navOpenBtn = $('.globalnav-open');
            // $navs = $container.find('li a');
            // $home = $container.find('.home a');

            $navOpenBtn.on('click', navOpenBtnClick.bind(this));
            $('body').on('click', '.globalnav a', close);
        };


        var navOpenBtnClick = function()
        {
            if ($container.hasClass('state-show'))
            {
                close();
            }
            else
            {
                open();
            }
        };

        var open = function()
        {
            $navOpenBtn.addClass('step1');
            setTimeout(function()
            {
                $navOpenBtn.removeClass('step1').addClass('step2');
            },300);
            $container.addClass('state-show');
        };

        var close = function()
        {
            $navOpenBtn.removeClass('step2').addClass('step1');
            setTimeout(function()
            {
                $navOpenBtn.removeClass('step1');
            }, 300);
            $container.removeClass('state-show');
        };



        initialize();

        return {
        };
    })();


    /*--------------------------------------------------
      regist App
    ----------------------------------------------------*/
    App.gnav = gnav;

})();