/*!
 *
 * home-mainvisual.js
 *
 */


var App = App || {};

(function(){

    // メインビジュアル
    var homeMainVisual = (function()
    {
        var
            $window,
            $intro,
            st,
            screen_w,
            screen_h,
            spacer_h,
            $container,
            $items,
            itemCount,
            image_files,
            $logo,
            $spacer,
            img_w,
            img_h,
            st_p,
            scroll_y ,
            interval,
            duration,
            current,
            old_current,
            timer,
            end_intro,
            images_loaded
            ;

        var initialize = function()
        {

            $window        = $(window);
            $intro        = $('#intro');
            $container     = $('#slideshow');
            $items         = $container.find('.item').css('opacity', 0).css('zIndex', 0);
            itemCount     = $items.length;

            $logo          = $intro.find('.logo');
            st             = 0;
            screen_w       = window.innerWidth ? window.innerWidth : $window.width();
            screen_h       = window.innerHeight ? window.innerHeight : $window.height();
            if (cssua.ua.mobile)
            {
                spacer_h    = screen_h * 2;
            } else {
                spacer_h    = screen_h + 500;
            }
            $spacer        = $('#spacer').css('height', spacer_h);

            //image_files    = [ 'img_02.jpg', 'img_04.jpg', 'img_05.jpg', 'img_06.jpg', 'img_07.jpg', 'img_08.jpg', 'img_09.jpg', 'img_10.jpg', 'img_11.jpg', 'img_12.jpg', 'img_13.jpg' ];
            img_w          = 2000;
            img_h          = 1200;
            st             = 0;
            st_p           = 1;
            scroll_y       = spacer_h - screen_h;
            interval       = 6400;
            current        = 0;
            old_current    = 0;
            timer          = null;
            end_intro = false;
            images_loaded  = false;


            // image_files = _.shuffle(image_files);
            // image_files.unshift('img_01.jpg');
            // var images = '';
            // for( var i = 0; i < 6; i++ ) {
            //     images += '<img src="/assets/img/index/mainvisual/'+ image_files[i] +'" alt="" class="item item'+ (i+1) +'">';
            // }
            // $container.append(images);
            // $items        = $container.find('.item').css('opacity', 0).css('zIndex', 0);
            // itemCount     = $items.length;
            $container.waitForImages(function()
            {
                images_loaded = true;
                showMainVisual();
            });

            startIntro();

            var throttledResizeEvent = _.throttle(onResize.bind(this), 100);
            var throttledScrollEvent = _.throttle(onScroll.bind(this), 120);

            if(cssua.ua.mobile)
            {
                /* 画面の傾き判定 */
                $window.on('orientationchange load', function(){
                    throttledResizeEvent();
                    if(Math.abs(window.orientation) === 90) {
                        //横表示
                        $('body').addClass('landscape');
                    } else {
                        //縦表示
                        $('body').removeClass('landscape');
                    }
                });

                /* スクロール */
                $window.on('touchstart touchmove touchend', function(){
                    throttledScrollEvent();
                });
            }
            else
            {
                $window.on('resize', function(){
                    throttledResizeEvent();
                });
                $window.on('scroll', function(){
                    throttledScrollEvent();
                });
            }
        };

        var startIntro = function()
        {
            var $logo1 = $('.logo1');
            var $logo2 = $('.logo2');
            var $logo3 = $('.logo3');

            var timeline = new TimelineMax();
            timeline
                .to($logo2, 1.4, {height: 198, delay:1.6, ease:Power3.easeOut})
                .to($logo2, 0.5, {right: 0, delay:0.1, ease:Power2.easeOut})
                .to($logo3, 1.2, {height: 457, delay:0.4, ease:Power2.easeOut})
                .to($logo1, 0.7, {opacity:1, top: 0, delay:0.2, ease:Power2.easeOut})
                ;

            timeline.addCallback(function()
            {
                end_intro = true;
                showMainVisual();
            });
            timeline.play();
        };

        var showMainVisual = function()
        {
            if ( end_intro === true && images_loaded === true )
            {
                onResize();
                play();
                resetTimer();
            }
        };

        var resetTimer = function()
        {
            stopTimer();
            timer = setInterval(function() {
                if ( current === ( itemCount - 1 ) ) {
                    current = 0;
                } else {
                    current++;
                }
                play();
            }, interval);
        };

        var stopTimer = function()
        {
            if ( timer ) {
                clearInterval( timer );
            }
        };

        var play = function()
        {
            $items.eq(old_current).css('zIndex', 0);
            $items.eq(current).css('zIndex', 1);
            TweenMax.to($items.eq(old_current), 1.8,
            {
                alpha: 0,
                ease : Power1.ease,
                delay : 0,
                onComplete:function()
                {
                }
            });
            TweenMax.to($items.eq(current), 1.8,
            {
                alpha: 1,
                ease : Power1.ease,
                delay : 0,
                onComplete:function()
                {
                    $items.not(':eq('+current+')').css({'opacity':0,'zIndex':0});
                    old_current = current;
                }
            });
        };

        var onScroll = function()
        {
            st = $window.scrollTop();
            st_p = 1 - ( st / scroll_y );

            if ( st < scroll_y ) {
                $logo.css({ 'opacity' : st_p });
            } else {
                $logo.css({ 'opacity' : 0 });
            }
        };

        var onResize = function()
        {
            screen_w = window.innerWidth ? window.innerWidth : $window.width();
            screen_h = window.innerHeight ? window.innerHeight : $window.height();
            if (cssua.ua.mobile)
            {
                spacer_h = screen_h * 2;
                $spacer.css('height', spacer_h);
                scroll_y = spacer_h - screen_h;
            }
            // $items.css({ 'width':screen_w, 'height':'auto' });
            // img_w = $items.eq(0).width();
            // img_h = $items.eq(0).height();
            // st = $window.scrollTop();
            // sl = $window.scrollLeft();
            // max_w = Math.floor( img_w*screen_h / img_h );
            // max_h = Math.floor( img_h*screen_w / img_w );

            $container.css({
                width: screen_w,
                height: screen_h
            });

            // if ( screen_w > max_w && screen_h < max_h ) {
            //     top = Math.floor( ( max_h - screen_h)*0.5 );
            //     left = Math.floor( ( max_w - screen_w)*0.5 );
            //     $items.css({
            //         width: screen_w,
            //         height: 'auto',
            //         marginTop: -top,
            //         marginLeft: 0
            //     });
            // } else if ( screen_w < max_w && screen_h > max_h ) {
            //     top = Math.floor( ( max_h - screen_h )*0.5 );
            //     left = Math.floor( ( max_w - screen_w )*0.5 );
            //     $items.css({
            //         width: 'auto',
            //         height: screen_h,
            //         marginTop: 0,
            //         marginLeft: -left
            //     });
            // }
        };

        return {
            initialize: initialize
        };
    })();


    /*--------------------------------------------------
      regist App
    ----------------------------------------------------*/
    App.homeMainVisual = homeMainVisual;

    $(function(){
        $('.page-home').each(function()
        {
            App.homeMainVisual.initialize();
        });
    });

}());

